import React from 'react';
import me from './ramonrecuero.jpg';
import styles from './picture.module.scss';
import { SocialIcon } from 'react-social-icons'

const ProfileHeader = () => (
  <div className={styles.picture}>
    <img src={me} alt="Ramon Recuero"/>
    <div className={styles.icons}>
      <SocialIcon url="http://linkedin.com/in/ramonrecuero" style={{ height: 25, width: 25 }} />
      <SocialIcon url="http://twitter.com/ramonrecuero" style={{ height: 25, width: 25 }} />
      <SocialIcon url="https://www.producthunt.com/@ramonrecuero" style={{ height: 25, width: 25 }} />
    </div>
  </div>
)

export default ProfileHeader;
